import React from "react"
import { graphql } from "gatsby"
import upperFirst from "lodash/upperFirst"

import Layout from "../components/layout"
import Seo from "../components/seo/seo"
import Section from "../components/section/section"
import Sidebar from "../components/sidebar/sidebar"
import RichText from "../components/richText/richText"
import Cta from "../components/cta/cta"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"

import { parseMarket } from "../consts/markets"
import { useTranslation } from "../helpers/useTranslation"
import { findAvailableModule } from "../helpers/findAvailableModule"

import * as styles from "./event.module.scss"

// It's imported this way because it's also used in gatsby-node
const generatePath = require("../helpers/generatePath")

const Event = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { content: event, seo } = data.sanityEvent.tabs
  const { _createdAt, _updatedAt } = data.sanityEvent
  const categories = data.allSanityEventCategory
  const { _rawCta, _rawFeaturedImage, _rawForm, _rawRichText, subtitle, hero, eventDate, type } = event
  const isPast = type === "previous"
  const translations = data.sanityTranslationEvent?.translation
  const enPathUrl = translations && generatePath(translations[0])
  const esPathUrl = translations && generatePath(translations[1])
  // const auPathUrl = translations && generatePath(translations[2])
  // const ptPathUrl = translations && generatePath(translations[3])
  const { market } = pageContext
  const link = market === "en-us" ? "events" : `${parseMarket(market)}/events`
  const image = event._rawFeaturedImage
  const richText = event?._rawRichText
  const video = findAvailableModule(richText, "video")

  const steps = [
    {
      link,
      title: "Events",
    },
    {
      link: `${link}/${event.category.slug.current}`,
      title: event.category.title,
    },
  ]

  return (
    <Layout
      translations={translations}
      enPathUrl={enPathUrl}
      esPathUrl={esPathUrl}
      // auPathUrl={auPathUrl}
      // ptPathUrl={ptPathUrl}
    >
      <Seo
        title={event.hero}
        image={image?.asset.url}
        path={pageContext.pagePath}
        datePublished={_createdAt}
        dateModified={_updatedAt}
        breadcrumbs={steps}
        video={video}
        {...seo}
      />
      <Breadcrumbs homePageLabel={t("events")} />
      <Section>
        <h1 className="text-align-center mb-xs gradient">{hero}</h1>
        <div className={styles.eventDescription}>{subtitle}</div>
      </Section>
      <div className="grid">
        <div className="gridItem large-3">
          <Sidebar
            entity={t("events")}
            formEvent={upperFirst(t("register"))}
            description={isPast ? <div className={"darkgrey mt-xs"}>{upperFirst(t("event passed"))}</div> : ""}
            image={_rawFeaturedImage}
            publishDate={eventDate}
            categories={categories}
            isFormDisabled={isPast}
            form={_rawForm}
          />
        </div>
        <div className="gridItem large-9 large-offset-4">
          <main>
            <RichText blocks={_rawRichText} />
          </main>
        </div>
      </div>
      {_rawCta && (
        <Section background="gradient">
          <Cta {..._rawCta} />
        </Section>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!, $market: String!, $_id: String!) {
    sanityEvent(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      _updatedAt(formatString: "MMMM Do, YYYY")
      _createdAt(formatString: "MMMM Do, YYYY")
      tabs {
        content {
          slug {
            current
          }
          _rawCta(resolveReferences: { maxDepth: 10 })
          _rawFeaturedImage(resolveReferences: { maxDepth: 10 })
          _rawForm(resolveReferences: { maxDepth: 10 })
          _rawRichText(resolveReferences: { maxDepth: 10 })
          subtitle
          hero
          type
          eventDate(formatString: "HH:mm, MMMM Do, YYYY")
          category {
            title
            slug {
              current
            }
          }
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
        }
      }
    }
    allSanityEventCategory(filter: { market: { eq: $market } }) {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
    sanityTranslationEvent(translation: { elemMatch: { tabs: { content: {} }, _id: { eq: $_id } } }) {
      translation {
        _id
        _type
        tabs {
          content {
            market
            slug {
              current
            }
            category {
              id
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`

export default Event
